.read-more {
    margin-bottom: 6rem;
    position: relative;
    *zoom: 1;

    &:before, 
    &:after {
        content: "";
        display: table;
    }

    &:after { clear: both }

    &__section:first-of-type {
        // float: right;
        // width: 99%;
    }

    &__section:last-of-type {
        display: none;
        visibility: hidden;
        max-height: 0;
    }

    &__section {
        @include rem-size(margin-top, 21px);
        transition: all 2.125s ease-in-out;
    }

    &__input[type=checkbox] {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        width: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;

        &:checked ~ .read-more__section {
            display: block;
            visibility: visible;
            width: 100%;
            max-height: 100%;

        }

        &:checked ~ .read-more__label span:first-of-type {
            display: none;
            visibility: hidden;
        }

        &:checked ~ .read-more__label span:last-of-type {
            display: block;
            visibility: visible;
        }

    }

    &__label {
        position: absolute;
        display: block;
        text-indent: 0;
        bottom: -60px;
        left: 0;
        height: auto;
        width: 100%;
        text-align: center;

        &:hover {
            background: $color-black;
            border: solid 1px $color-black;
            color: $color-white;
        }

        span:last-of-type {
            display: none;
            visibility: hidden;
        }
    }


}












