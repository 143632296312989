// content box

.content-box {
	@include clearfix;
	@include rem-size(padding, 20px 0);
	border-top: solid 1px $color-light-grey-5;

	@include breakpoint($laptop) {
		@include rem-size(padding, 40px 0);
	}
	
	&__left {
		@include susy-breakpoint($tablet-portrait, 6) {
			@include rem-size(padding-bottom, 40px);
			@include span(3);
		}

		@include susy-breakpoint($laptop, 12) {
			@include rem-size(padding-bottom, 60px);
			@include span(6);
		}
	}

	&__right {
		@include rem-size(margin-top, 40px);

		@include susy-breakpoint($tablet-portrait, 6) {
			@include span(3 last);
			margin-top: 0;
			padding-left:gutter(12);		
		}

		@include susy-breakpoint($laptop, 12) {
			@include span(6 last);
		}

		img {
			display: inherit;
		}
	}	
}

