// placeholder

%button {
	@include rem-size(font-size, $xsmall-size);
    @include rem-size(line-height, $xsmall-leading);
    @include rem-size(padding, 15px 20px);
	background: none;
	border: solid 1px $color-black;
	cursor: pointer;
	font-family: $font-regular;
	color: $color-black;
	text-transform: uppercase;
	text-align: center;
	vertical-align: middle;
	outline: none;	
    text-transform: uppercase;

    @include breakpoint($tablet-portrait) {
		@include rem-size(font-size, $xsmall-size__tablet);
    	@include rem-size(line-height, $xsmall-leading__tablet);
    }

    @include breakpoint($laptop) {
		@include rem-size(font-size, $xsmall-size__laptop);
    	@include rem-size(line-height, $xsmall-leading__laptop);
    }

    @include breakpoint($desktop) {
		@include rem-size(font-size, $xsmall-size__desktop);
    	@include rem-size(line-height, $xsmall-leading__desktop);
    }

    &:hover {
    	background: $color-white;
    	color: $color-black;
    }

}

// button

button {
	border:0;
	background: none;

	span {
		position: relative;
		top:-2px;
	} 
}

.btn {
	@extend %button;

	&--full {
		@extend %button;
		display: block;
		width: 100%;
	}
}