// footer

.footer {
	@include rem-size(padding, 30px 0);
	@include rem-size(margin-top, 40px);
	background: $color-dark-grey-3 ;

	@include breakpoint($tablet-portrait) { 
		@include rem-size(padding, 40px 0);
	}

	@include breakpoint($laptop) {
		@include rem-size(padding, 60px 0);
	}

	&__headline {
		@include rem-size(font-size, $small-size);
	    @include rem-size(line-height, $small-leading); 
	    @include rem-size(margin-bottom, $xbase-leading);
	    color: $color-light-grey-6;
	    text-transform: uppercase;

	    @include breakpoint($tablet-portrait) { 
	        @include rem-size(font-size, $small-size__tablet);
	        @include rem-size(line-height, $small-leading__tablet);
	        @include rem-size(margin-bottom, $small-leading__tablet);        
	    } 

	    @include breakpoint($laptop) {
	        @include rem-size(font-size, $small-size__laptop);
	        @include rem-size(line-height, $small-leading__laptop);  
	        @include rem-size(margin-bottom, $small-leading__laptop); 
	    }
	        

	    @include breakpoint($desktop) {
	        @include rem-size(font-size, $small-size__desktop);
	        @include rem-size(line-height, $small-leading__desktop); 
	        @include rem-size(margin-bottom, $small-leading__desktop); 
	    }
	}

	&__paragraph {
		@include rem-size(font-size, $small-size);
	    @include rem-size(line-height, $small-leading); 
	    @include rem-size(margin-bottom, $small-leading);
	    color: $color-light-grey-6;
	    text-transform: uppercase;

	    @include breakpoint($tablet-portrait) { 
	        @include rem-size(font-size, $small-size__tablet);
	        @include rem-size(line-height, $small-leading__tablet);
	        @include rem-size(margin-bottom, $small-leading__tablet);        
	    } 

	    @include breakpoint($laptop) {
	        @include rem-size(font-size, $small-size__laptop);
	        @include rem-size(line-height, $small-leading__laptop);  
	        @include rem-size(margin-bottom, $small-leading__laptop); 
	    }
	        

	    @include breakpoint($desktop) {
	        @include rem-size(font-size, $small-size__desktop);
	        @include rem-size(line-height, $small-leading__desktop); 
	        @include rem-size(margin-bottom, $small-leading__desktop); 
	    }
	}

	.fa {
		margin-right: 10px;
	}
	.fa-facebook {
		margin-right: 14px; // override
	}
	.fa-copyright {
		margin-right: 0; // override
	}

	&__link {
		color: $color-light-grey-6;

		&:hover {
			color: $color-white;
			text-decoration: none;
		}
	}

	&__col-one {
		@include rem-size(margin-bottom, 40px);
		
		@include susy-breakpoint($tablet-portrait, 6) {
			margin-bottom: 0;
			@include span(3);
		}

		@include susy-breakpoint($laptop, 12) {
			@include rem-size(margin-bottom, 40px);
			@include span(4);
		}
	}

	&__col-two {
		@include rem-size(margin-bottom, 40px);

		@include susy-breakpoint($tablet-portrait, 6) {
			margin-bottom: 0;
			@include span(3 last);
		}

		@include susy-breakpoint($laptop, 12) {
			@include rem-size(margin-bottom, 40px);
			@include span(4);
		}
	}

	&__col-three {
		@include rem-size(margin-bottom, 40px);

		@include susy-breakpoint($tablet-portrait, 6) {
			@include rem-size(margin-top, 40px);
			@include span(3);
			margin-bottom: 0;
		}

		@include susy-breakpoint($laptop, 12) {
			margin-top: 0;
			@include span(2 last);
		}
	}

	&__col-four {
		@include rem-size(margin-bottom, 40px);

		@include susy-breakpoint($tablet-portrait, 6) {
			@include rem-size(margin-top, 40px);
			@include span(3);
			margin-bottom: 0;
		}

		@include susy-breakpoint($laptop, 12) {
			margin-top: 0;
			@include span(4);
		}
	}

	&__col-five {
		img {
			@include rem-size(padding-top, 5px);
		}
		@include susy-breakpoint($tablet-portrait, 6) {
			@include rem-size(margin-top, 40px);
			@include span(3 last);
			margin-bottom: 0;
		}

		@include susy-breakpoint($laptop, 12) {
			margin-top: 0;
			@include span(2 last);
		}
	}

	.handmade {
		@include rem-size(font-size, $base-size);
	    @include rem-size(line-height, $base-leading);
		color: $color-medium-grey-2;
		margin-bottom: 0;

	    @include breakpoint($tablet-portrait) {
	        @include rem-size(font-size, $base-size__tablet);
	        @include rem-size(line-height, $base-leading__tablet);
	    }

	    @include breakpoint($laptop) {
	        @include rem-size(font-size, $base-size__laptop);
	        @include rem-size(line-height, $base-leading__laptop);  
	    }

	    @include breakpoint($desktop) {
	        @include rem-size(font-size, $base-size__desktop);
	        @include rem-size(line-height, $base-leading__desktop);
	    } 
	}

	&__bottom {			
		
		&__paragraph {
			@include rem-size(font-size, $small-size);
		    @include rem-size(line-height, $small-leading);
		    margin: 0; 
		    color: $color-medium-grey-2;

		    @include breakpoint($tablet-portrait) { 
		        @include rem-size(font-size, $tiny-size__tablet);
		        @include rem-size(line-height, $tiny-leading__tablet);        
		    } 

		    @include breakpoint($laptop) {
		        @include rem-size(font-size, $tiny-size__laptop);
		        @include rem-size(line-height, $tiny-leading__laptop);   
		    }
		}

		&__link {
			color: $color-medium-grey-2;

			&:hover {
				color: $color-white;
				text-decoration: none;
			}
		}

	}

}


