input, textarea, select {
   -webkit-appearance: none;
   border-radius: 0;   
}

:invalid { 
    border-color: $color-warning;
    -moz-box-shadow:    3px 3px 5px 6px #ccc;
    -webkit-box-shadow: 3px 3px 5px 6px #ccc;
    box-shadow:         3px 3px 5px 6px #ccc;
}

:required {
    border-color: $color-action;
    color:$color-black;
    -moz-box-shadow:    3px 3px 5px 6px #ccc;
    -webkit-box-shadow: 3px 3px 5px 6px #ccc;
    box-shadow:         3px 3px 5px 6px #ccc;
}

form,
fieldset {
    border: none;
}

label {
    @include rem-size(margin-bottom, 10px);
    @include rem-size(padding-bottom, 5px);
    @include rem-size(font-size, $tiny-size);
    @include rem-size(line-height, $tiny-leading);
    @include rem-size(padding-right, 10px);
    text-transform: uppercase;
    display: inline-block;
    color: $color-dark-grey-3;  

    @include breakpoint($tablet-portrait) {
        @include rem-size(font-size, $tiny-size__tablet);
        @include rem-size(line-height, $tiny-leading__tablet);
    }

    @include breakpoint($laptop) {
        @include rem-size(font-size, $tiny-size__desktop);
        @include rem-size(line-height, $tiny-leading__desktop);
    }
}

input {
    font-family: $font-regular;
    @include rem-size(font-size, $small-size);
    @include rem-size(line-height, $small-leading);
    @include rem-size(padding, 0 10px);
    border: none;
    background: $color-light-grey-3;
    width: 100%;
    color:$color-black;     
    display: block;
    margin-left: 0;
    outline: none; 
    @include rem-size(height, 48px); 

    @include breakpoint($tablet-portrait) {
        @include rem-size(font-size, $small-size__tablet);
        @include rem-size(line-height, $small-leading__tablet);
    }

    @include breakpoint($laptop) {
        @include rem-size(font-size, $small-size__desktop);
        @include rem-size(line-height, $small-leading__desktop);
    }      

    &:active,
    &:focus {
        border: solid 1px $color-black;    
    }
}

textarea {
    @include rem-size(height, 229px); 
    @include rem-size(font-size, $xsmall-size);
    @include rem-size(line-height, $xsmall-leading);
    @include rem-size(padding, 20px 10px);
    background: $color-light-grey-3;
    font-family: $font-regular;
    border: none;
    width: 100%;
    color:$color-light-grey;   
    margin-left: 0;
    outline: none;
    @include rem-size(padding-left, 15px);
    @include rem-size(padding-right, 15px);

    @include breakpoint($tablet-portrait) {
        @include rem-size(font-size, $xsmall-size__tablet);
        @include rem-size(line-height, $xsmall-leading__tablet);
    }

    @include breakpoint($laptop) {
        @include rem-size(font-size, $xsmall-size__desktop);
        @include rem-size(line-height, $xsmall-leading__desktop);
    }            

    &:active,
    &:focus {
        border: solid 1px $color-black;    
    }  
}

[type=checkbox]  {
    -webkit-appearance: none;
    background-color: $color-light-grey-3;
    border: 1px solid $color-light-grey-3;
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
    padding: 9px;
    border-radius: 3px;
    width:20px;
    height: 20px;
    display: inline-block;
    position: relative;

    &:active, 
    &:checked:active {
        box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
    }

    &:checked {
        background-color: #e9ecee;
        border: 1px solid #adb8c0;
        box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1);
        color: $color-black;
    }    

    &:checked:after {
        content: '\2714';
        font-size: 14px;
        position: absolute;
        top: 0px;
        left: 3px;
        color: $color-black;
    }   
}

[type=radio]  {
    -webkit-appearance: none;
    background-color: $color-light-grey-3;
    border: 1px solid $color-light-grey-3;
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
    padding: 9px;
    border-radius: 50px;
    display: inline-block;
    position: relative;
    width:20px;
    height: 20px;
    
    &:checked:after {
        content: ' ';
        width: 12px;
        height: 12px;
        border-radius: 50px;
        position: absolute;
        top: 3px;
        background: $color-main;
        box-shadow: inset 0px 0px 10px rgba(0,0,0,0.3);
        text-shadow: 0px;
        left: 3px;
        font-size: 32px;
    }

    &:checked {
        background-color: $color-light-grey-3;
        color: $color-black;
        border: 1px solid $color-light-grey-3;
        box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05), inset 15px 10px -12px rgba(255,255,255,0.1), inset 0px 0px 10px rgba(0,0,0,0.1);
    }

    &:active,
    &:checked:active {
        box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
    }
}

[type=submit]  {
    @extend %button;
    background: $color-dark-grey-3;
    color:$color-white;        
}