table {
    @include rem-size(margin, 20px 0);
    border:none;
    border-spacing: 0;
    border-collapse: collapse;
    width:100%;

    @include breakpoint($tablet-portrait) {
		@include rem-size(margin, 40px 0);
	}

	@include breakpoint($laptop) {
		@include rem-size(margin, 60px 0);
	}

	@include breakpoint($desktop) {
		@include rem-size(margin, 80px 0);
	}

	th {
	    @include rem-size(font-size, $base-size);
	    @include rem-size(line-height, $base-leading);
		@include rem-size(padding, 10px);	    
	    text-align:left;
	    margin:0;
	    border: none;	   
		font-family:$font-regular;	  
	    color: $color-dark-grey;   
	
	    @include breakpoint($tablet-portrait) {
	        @include rem-size(font-size, $base-size__tablet);
	        @include rem-size(line-height, $base-leading__tablet);
	    }

	    @include breakpoint($laptop) {
	        @include rem-size(font-size, $base-size__laptop);
	        @include rem-size(line-height, $base-leading__laptop);   
	    }

	    @include breakpoint($desktop) {
	        @include rem-size(font-size, $base-size__desktop);
	        @include rem-size(line-height, $base-leading__desktop);
	    }   
	}

	td {
	    @include rem-size(font-size, $xbase-size);
	    @include rem-size(line-height, $xbase-leading);
		@include rem-size(padding, 10px 5px);	    
	    text-align:left;
	    margin:0;
	    border: none;	   
		font-family:$font-regular;	  
	    color: $color-dark-grey-3;  
	
	    @include breakpoint($tablet-portrait) {
	        @include rem-size(font-size, $xbase-size__tablet);
	        @include rem-size(line-height, $xbase-leading__tablet);
	    }

	    @include breakpoint($laptop) {
	        @include rem-size(font-size, $xbase-size__laptop);
	        @include rem-size(line-height, $xbase-leading__laptop); 
	        @include rem-size(padding, 15px 5px);  
	    }

	    @include breakpoint($desktop) {
	        @include rem-size(font-size, $xbase-size__desktop);
	        @include rem-size(line-height, $xbase-leading__desktop);
	    }   
	}

	th {
	    border-bottom: solid 3px $color-main;
	    @include rem-size(padding, 10px 5px);

	    @include breakpoint($laptop) {
	        @include rem-size(padding, 15px 5px);  
	    }
	}
		
	tr {
	    border-bottom: 1px solid $color-light-grey-2;

	    &:last-child {
	    	border-bottom: none;
	    } 
	}
}

	