a {
    -webkit-transition: all 0.5s ease; 
    -moz-transition: all 0.5s ease; ;
    -o-transition: all 0.5s ease; ;
    transition: all 0.5s ease; ;
    text-decoration: none;
    color: $color-medium-grey;

    &:hover {
    	color: $color-medium-grey-2;
        text-decoration: none; 
        -webkit-transition: opacity 0.5s ease;
        -moz-transition: opacity 0.5s ease;
        -o-transition: opacity 0.5s ease;
        transition: opacity 0.5s ease;
        cursor: pointer;
    }
}
a:focus,
a:active,
a:hover {
    outline:0;
}	