// objects header

.objects__header {
	@extend .c;	
}

// objects header

.objects {
	@include clearfix;

	&__item {
		@include gallery(2 of 4);
		margin-bottom: gutter(3);

		@include susy-breakpoint($mobile-horizontal, 6) {
			@include gallery(2 of 6);
			margin-bottom: gutter(6);
		}

		@include susy-breakpoint($tablet-portrait, 6) {
			@include gallery(2 of 6);
			margin-bottom: gutter(6);
		}

		@include susy-breakpoint($laptop, 12) {
			@include gallery(3 of 12);
			margin-bottom: gutter(6);
		}

		.small-headline {
			padding-top: 10px;
			margin-bottom: 0;
			background:#fff;
			position:relative;
			z-index: 3;
		}
		
		img,
		a {
			display: block;
		}
	
		a {			
			position:relative;
			z-index:1;

			&:hover:after {
				opacity: 0.2;
			}
		
			&:after {
				position:absolute;
				top:0;
				bottom:10px;
				left:0;
				right:0;
				display: block;
				content:"";
				opacity:0;
				-webkit-transition: opacity 0.5s ease;
				-moz-transition: opacity 0.5s ease;
				-o-transition: opacity 0.5s ease;
				transition: opacity 0.5s ease;
				background: #000;
				z-index:2;
			}			
		}	
	
		&__nohover {
			a {
				&:after {
					position:static;
				}
			}
		}
	}
}

// object content

.object-content {
	@include clearfix;

	@include breakpoint($tablet-portrait) {
		position:relative; 
		overflow:hidden;
	}
}

// object header

.placeholder-main {
	@include clearfix;
	background: white;
}
.object-header {	
	@include rem-size(margin-bottom, 20px);

	@include breakpoint($mobile-horizontal) {
		@include rem-size(margin-bottom, 40px);
		padding-top: 0;
	}

	@include susy-breakpoint($tablet-portrait, 6) {
		@include rem-size(margin-bottom, 40px);
		@include span(3);
		margin-bottom: 0;
		padding: 0;
	}

	@include susy-breakpoint($laptop, 12) {
		@include rem-size(margin-bottom, 60px);
		@include span(4);
	}

	.large-headline {
		margin-bottom: 0;
		text-transform: uppercase;
	}

	.info-text {
		margin-bottom: 0;
	}
}

// object main content

.object-main-content {	
	@include clearfix;	
}

// object main image

.object-main-image {	
	@include rem-size(margin-bottom, 20px);

	@include susy-breakpoint($tablet-portrait, 6) {
		@include span(3 last);	
		@include rem-size(margin-bottom, 40px);
		margin: 0;
	}

	@include susy-breakpoint($laptop, 12) {
		@include span(6 last);	
		@include rem-size(margin-bottom, 40px);
	}

	@include susy-breakpoint($desktop-wide, 12) {
		@include span(7 last);	
		@include rem-size(margin-bottom, 60px);
	}

	.numbers {
		left:0;
	    right:0;
	    margin-left:auto;
	    margin-right:auto;
	}

	&__thumbs {
		@include clearfix;

		&__item {
			@include gallery(1 of 4);
			cursor: pointer;			
			
			@include susy-breakpoint($tablet-portrait, 6) {
				@include gallery(1.5 of 6);
				margin-bottom:gutter(12);
			}

			@include susy-breakpoint($laptop, 12) {
				@include gallery(2 of 12);
			}

			img {
				display: block;
			}
		}
	}
}

// object placeholder

.object-placeholder {

	@include susy-breakpoint($tablet-portrait, 6) {
		@include span(3);
		padding-right:gutter(12);
		padding-top: 60px;			
	}

	@include susy-breakpoint($laptop, 12) {
		@include span(6);
		padding-right:gutter(9);
		padding-top: 120px;	
	}

	@include susy-breakpoint($desktop, 12) {
		padding-right:gutter(9);
	}

	@include susy-breakpoint($desktop-wide, 12) {
		@include span(5);
		padding-top: 120px;	
	}
}



// object description

.object-description {	
	@include rem-size(margin-bottom, 20px);

	@include susy-breakpoint($tablet-portrait, 6) {
		@include rem-size(margin-bottom, 40px);			
	}

	@include susy-breakpoint($laptop, 12) {
		@include rem-size(margin-bottom, 60px);	
	}
}