// sub-nav

.sub-nav {			
	@include rem-size(margin-top, 20px);
	@include rem-size(padding, 15px 5px);
	display: table;
	width: 100%;
	border-top: 1px solid $color-light-grey-5;	
	border-bottom: 1px solid $color-light-grey-5;

	@include breakpoint($tablet-portrait) {
		@include rem-size(margin-top, 40px);
	}

	@include breakpoint($laptop) {
		@include rem-size(margin-top, 60px);
	}

	&__paragraph {
		@include rem-size(font-size, $xsmall-size__desktop);
		@include rem-size(line-height, $xsmall-leading__desktop);
		@include rem-size(padding-right, 15px);
		@include rem-size(width, 50px);
		display: table-cell;
		vertical-align: top;
		font-family: $font-light;
		text-transform: uppercase;			
	}

	&__list {					
		@extend %list-no-bullets;	
		@include clearfix;
		display: table-cell;
		vertical-align: top;
		text-transform: uppercase;	

		&__item {	
			@include rem-size(font-size, $xsmall-size__desktop);
    		@include rem-size(line-height, $xsmall-leading__desktop);
    		@include rem-size(margin-right, 20px);

    		margin-bottom: 0;
			font-family: $font-bold;
			float: left;
			
			&:first-child {
				padding-left: 0;
			}			

			&:last-child {
				
				margin-right: 0;
			}			
		}
	}

	&__link {
		display: inline-block;
		color: $color-black;
		
		&:hover {
			color: $color-medium-grey-2;
	        text-decoration: none; 
	        -webkit-transition: opacity 0.5s ease;
	        -moz-transition: opacity 0.5s ease;
	        -o-transition: opacity 0.5s ease;
	        transition: opacity 0.5s ease;
	        cursor: pointer;
		}       
	}
}		
