// designer overview

.designer-overview {
	@include clearfix;
	@include rem-size(margin-top, 6px);

	@include susy-breakpoint($desktop, 12) {
		@include rem-size(margin-top, 12px);
	}
}

