// mobile

.mob {
    display: none;

    @include breakpoint($tablet-portrait) {
        display: inline-block;
    }
}

// no margin top

.no-margin-top {
    margin-top: 0!important;
}

// caption

.caption {
    @extend small;  
}

// uppercase

.case {
    text-transform: uppercase !important;
}

// lower uppercase

.lcase {
    text-transform: none !important;
}

// strong

.strong {
    font-family: $font-bold !important;
}

// light

.light {
    font-family: $font-light !important;
}

// full width

.full {
    width: 100%;
}

// black

.black {
    color: $color-black;
}

// white

.white {
    color: $color-white;
}

// medium grey

.medium-grey {
    color: $color-medium-grey; 
}

// bg grey

.bg-grey {
    background: $color-light-grey-3;
}

// border bottom

.border-bottom {
    border-bottom: solid 1px $color-light-grey-5;
}

// align left

.align-left {
    text-align: left;
}

// align right

.align-right {
    text-align: right;
}

// align center

.align-center {
    text-align: center;
}

// float left

.left {
    float: left;
}

// float right

.right {
    float: right;
}

// img full

.img-full {
    width:100%;
}

// small paragraph

.small {
    @extend small;
}

// small headline

.small-headline {
    @include rem-size(font-size, $small-size);
    @include rem-size(line-height, $small-leading);
    text-transform: uppercase;

    @include breakpoint($tablet-portrait) {
        @include rem-size(font-size, $small-size__tablet);
        @include rem-size(line-height, $small-leading__tablet);
    }

    @include breakpoint($laptop) {
        @include rem-size(font-size, $small-size__laptop);
        @include rem-size(line-height, $small-leading__laptop);
    }

    @include breakpoint($desktop) {
        @include rem-size(font-size, $small-size__desktop);
        @include rem-size(line-height, $small-leading__desktop);
   }

    .light {
        font-family: $font-light;
   }
}

// marker

.marker {
    @include rem-size(font-size, $xbase-size);
    @include rem-size(line-height, $xbase-leading);
    text-transform: uppercase;
    font-family: $font-bold;

    @include breakpoint($tablet-portrait) {
        @include rem-size(font-size, $xbase-size__tablet);
        @include rem-size(line-height, $xbase-leading__tablet);
    }

    @include breakpoint($laptop) {
        @include rem-size(font-size, $xbase-size__laptop);
        @include rem-size(line-height, $xbase-leading__laptop);
    }

    @include breakpoint($desktop) {
        @include rem-size(font-size, $xbase-size__desktop);
        @include rem-size(line-height, $xbase-leading__desktop);
   }

    .light {
        font-family: $font-light;
   }
}


// medium headline

.medium-headline {
    @include rem-size(font-size, $medium-size);
    @include rem-size(line-height, $medium-leading); 
    @include rem-size(margin-bottom, $medium-leading/2);

    @include breakpoint($tablet-portrait) {
        @include rem-size(font-size, $medium-size__tablet);
        @include rem-size(line-height, $medium-leading__tablet);   
        @include rem-size(margin-bottom, $medium-leading__tablet/2);  
    }   

    @include breakpoint($laptop) {
        @include rem-size(font-size, $medium-size__laptop);
        @include rem-size(line-height, $medium-leading__laptop); 
        @include rem-size(margin-bottom, $medium-leading__laptop/2);   
    }

    @include breakpoint($desktop) {
        @include rem-size(font-size, $medium-size__desktop);
        @include rem-size(line-height, $medium-leading__desktop);
        @include rem-size(margin-bottom, $medium-leading__desktop/2); 
    }
}

// big paragraph

.large-headline {
    @include rem-size(font-size, $xxmedium-size);
    @include rem-size(line-height, $xxmedium-leading); 

    @include breakpoint($tablet-portrait) {
        @include rem-size(font-size, $xxmedium-size__tablet);
        @include rem-size(line-height, $xxmedium-leading__tablet);
    }

    @include breakpoint($laptop) {
        @include rem-size(font-size, $xxmedium-size__laptop);
        @include rem-size(line-height, $xxmedium-leading__laptop);
    }

    @include breakpoint($desktop) {
        @include rem-size(font-size, $xxmedium-size__desktop);
        @include rem-size(line-height, $xxmedium-leading__desktop);
   }
}

// info text

.info-text {
    @include rem-size(font-size, $xsmall-size);
    @include rem-size(line-height, $xsmall-leading); 
    color: $color-light-grey-4;
    text-transform: uppercase;

    @include breakpoint($tablet-portrait) {
        @include rem-size(font-size, $xsmall-size__tablet);
        @include rem-size(line-height, $xsmall-leading__tablet);
    }

    @include breakpoint($laptop) {
        @include rem-size(font-size, $xsmall-size__laptop);
        @include rem-size(line-height, $xsmall-leading__laptop);
    }

    @include breakpoint($desktop) {
        @include rem-size(font-size, $xsmall-size__desktop);
        @include rem-size(line-height, $xsmall-leading__desktop);
   }
}