// general

ul,
ol {
	@include rem-size(padding-left, 21px);
	@include rem-size(margin-bottom, $xbase-leading*1.5);

    @include breakpoint($tablet-portrait) {
        @include rem-size(margin-bottom, $xbase-leading__tablet*1.5);
    }

    @include breakpoint($laptop) {
        @include rem-size(margin-bottom, $xbase-leading__laptop*1.5); 
    }

    @include breakpoint($desktop) {
        @include rem-size(margin-bottom, $xbase-leading__desktop*1.5);
    } 

	li {
		@include rem-size(font-size, $xbase-size);
	    @include rem-size(line-height, $xbase-leading);
	    color: $color-medium-grey ;  
	    font-family: $font-regular;    
	    text-align:left;

	    @include breakpoint($tablet-portrait) {
	        @include rem-size(font-size, $xbase-size__tablet);
	        @include rem-size(line-height, $xbase-leading__tablet);
	    }

	    @include breakpoint($laptop) {
	        @include rem-size(font-size, $xbase-size__laptop);
	        @include rem-size(line-height, $xbase-leading__laptop);   
	    }

	    @include breakpoint($desktop) {
	        @include rem-size(font-size, $xbase-size__desktop);
	        @include rem-size(line-height, $xbase-leading__desktop);
	    }  
	}	
}
ul {
	list-style-type: square;
}
ol {
	list-style-type: decimal;
}

// info-list

.info-list-header {
	@include rem-size(padding, 5px);
}

.info-list {
	@extend %list-no-bullets;
	border-top: 1px solid $color-light-grey-5;	

	&__item {
		@include rem-size(line-height, $xsmall-leading); 
	    @include rem-size(margin-bottom, $small-leading);
	    border-bottom: 1px solid $color-light-grey-5;	
		text-transform: uppercase;
		@include rem-size(font-size, $xsmall-size);

	    @include breakpoint($tablet-portrait) { 
	        @include rem-size(font-size, $xsmall-size__tablet);
	        @include rem-size(line-height, $xsmall-leading__tablet);       
	    } 

	    @include breakpoint($laptop) {
	        @include rem-size(font-size, $xsmall-size__laptop);
	        @include rem-size(line-height, $xsmall-leading__laptop);   
	    }  

	    @include breakpoint($desktop) {
	        @include rem-size(font-size, $xsmall-size__desktop);
	        @include rem-size(line-height, $xsmall-leading__desktop); 
	    } 
	}

	.last {
		border-bottom: none;
	}

	&__link {
		@include rem-size(padding, 15px 5px);
		color: $color-black;
		display: block;

		&__narrow {
			@include rem-size(padding, 7px 5px);
		}

		&:hover {
			color: $color-medium-grey-2;
	        text-decoration: none; 
	        -webkit-transition: opacity 0.5s ease;
	        -moz-transition: opacity 0.5s ease;
	        -o-transition: opacity 0.5s ease;
	        transition: opacity 0.5s ease;
	        cursor: pointer;
		}
	}

	// Rotate
	.info-list__link.expanded .fa-long-arrow-down {
		transform: rotate(180deg);
	}

	i {
		float: right;
	}

	&__level-two {
		@extend %list-no-bullets;
		@include rem-size(margin-top, 10px);
		@include rem-size(margin-bottom, 10px);
		border:none;

		&__item {
			@include rem-size(font-size, $xsmall-size__desktop);
    		@include rem-size(line-height, $xsmall-leading__desktop);
    		@include rem-size(margin-bottom, 5px);
    		font-family: $font-light;
    		text-transform: uppercase;
			padding-left: 0;
			padding-right: 0;
		}

		a {
			@include rem-size(padding, 5px 5px);
		}
	}
}

// small-list

.small-list {
	@extend %list-no-bullets;

	&__item {
		@include rem-size(font-size, $small-size);
    	@include rem-size(line-height, $small-leading);
    	color: $color-medium-grey; 	
    	margin:0;
    	padding: 0;

    	@include breakpoint($tablet-portrait) { 
	        @include rem-size(font-size, $small-size__tablet);
	        @include rem-size(line-height, $small-leading__tablet);       
	    } 

	    @include breakpoint($laptop) {
	        @include rem-size(font-size, $small-size__laptop);
	        @include rem-size(line-height, $small-leading__laptop);  
	    }  

	    @include breakpoint($desktop) {
	        @include rem-size(font-size, $small-size__desktop);
	        @include rem-size(line-height, $small-leading__desktop);  
	    }
	}

	&__link {
		color: $color-medium-grey; 	

		&:hover {
			color: $color-medium-grey; 	
		}
	}
}