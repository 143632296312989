/* Custom Nav */
.owl-carousel-wrapper {
	position: relative;
	overflow: hidden;

	&:hover .owl-expand:after,
	&:hover .owl-prev:after,
	&:hover .owl-next:after {
		transform: translate3d(0, 0, 0);
	}

}

.owl-expand,
.owl-prev,
.owl-next {
	position: absolute;
	top:0;
	bottom:20px;
	z-index: 9;
	cursor: pointer;
	text-indent: -9999px;

	&:after {
		transition:transform .2s ease-in-out;
	  	font: normal normal normal 14px/1 FontAwesome;
	  	position: absolute;
		top:48%;
		text-indent: 0px;
		@include rem-size(padding, 10px);
		background: $color-black;
		//border:1px $color-light-grey-4 solid;
		display: block;
		color:$color-white;
	}

}

.owl-prev {
	left: 0;

	&:after {
		content: "\f177";
		left:0;
		transform: translate3d(-101%, 0, 0);
	}
}

.owl-next {
	right: 0px;

	&:after {
		content: "\f178";
		right:0;
		transform: translate3d(101%, 0, 0);
	}
}

// .owl-expand {
// 	right: 0px;
// 	bottom:auto;
// 	top:0px;

// 	@include breakpoint($tablet-portrait) {
// 		top:40px;
// 	}

// 	@include breakpoint($laptop) {
// 		top:0;
// 	}


// 	&:after {
// 		content: "\f065";
// 		top:0;
// 		right:0;
// 		transform: translate3d(101%, 0, 0);
// 	}
// }

.owl-thumb {
	cursor: pointer;
	margin-right: 10px;
	margin-left: 10px;
}

.owl-theme .owl-controls {
	margin-top: 0;
}

.object-main-content .owl-theme .owl-controls .owl-page span {
	border-radius: 0;
}
