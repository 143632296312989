// product overview

.product-overview {
	@include clearfix;
	@include rem-size(margin-top, 6px);

	@include susy-breakpoint($desktop, 12) {
		@include rem-size(margin-top, 12px);
	}
}

// products

.products {
	margin-top: span(1);

	&__item {
		@include gallery(2 of 4);

		margin-bottom: gutter(12);

		@include susy-breakpoint($tablet-portrait, 6) {
			@include gallery(2 of 6);
		}

		@include susy-breakpoint($laptop, 12) {
			@include gallery(3 of 12);
		}

		@include susy-breakpoint($desktop, 12) {
			@include gallery(2 of 12);
		}

		.small-headline {
			margin-bottom: 0;
		}
	}
}

// product content

.product-content {
	@include clearfix;

	@include susy-breakpoint($tablet-portrait, 6) {
		position:relative; 
		overflow:hidden;
	}
}

// product header
.placeholder-main {
	@include clearfix;
	background: white;
}
.product-header {	
	//padding-top: gutter(6);
	//margin-bottom: gutter(6);

	@include breakpoint($mobile-horizontal) {
		padding-top: 0;
	}

	@include susy-breakpoint($tablet-portrait, 6) {
		margin-bottom: 0;
		position:relative;
		padding: 0;
		top:92px;
	}

	@include susy-breakpoint($desktop, 12) {
		position:relative;
		//margin-bottom: gutter(12);
		//top:120px;
	}

	.large-headline {
		margin-bottom: 0;
		text-transform: uppercase;
	}
}

// product main content

.product-main-content {	
	@include clearfix;	
}

// product main image

.product-main-image {	
	margin-bottom:span(1);

	@include susy-breakpoint($tablet-portrait, 6) {
		@include span(3 last);	
		margin: 0;
	}

	@include susy-breakpoint($laptop, 12) {
		@include span(6 last);	
	}

	.numbers {
		left:0;
	    right:0;
	    margin-left:auto;
	    margin-right:auto;
	}

	&__thumbs {
		@include clearfix;

		&__item {
			@include gallery(1 of 4);
			cursor: pointer;
			margin-bottom:gutter(12);
			
			@include susy-breakpoint($tablet-portrait, 6) {
				@include gallery(1.5 of 6);
			}

			@include susy-breakpoint($laptop, 12) {
				@include gallery(2 of 12);
			}

			img {
				display: block;
			}
		}
	}
}

// product placeholder

.product-placeholder {
	padding-right:gutter(12);

	@include susy-breakpoint($tablet-portrait, 6) {
		@include span(3);
		padding-top: 120px;	
	}

	@include susy-breakpoint($laptop, 12) {
		@include span(5);	
	}
}



// product description

.product-description {	
	padding-bottom: span(2);
}

// product options

.product-options {	
	
	.info-list {
		i {
			float: right;
		}
	}
}
