html, body {
    color: $color-black;
}

// Headings 
h1, h2, h3, h4 {
    margin:0;
    display: inline-block;
    color: $color-dark-grey-3;
    width: 100%;
    text-align:left;
    text-rendering: optimizeLegibility; 
    font-weight: normal;  
    font-family: $font-bold;
}

h1 {
    @include rem-size(font-size, $large-size);
    @include rem-size(line-height, $large-leading);
    @include rem-size(margin-bottom, $large-leading); 
    font-family: $font-regular;
    text-transform: uppercase;

    @include breakpoint($tablet-portrait) {
        @include rem-size(font-size, $large-size__tablet);
        @include rem-size(line-height, $large-leading__tablet);     
        @include rem-size(margin-bottom, $large-leading__tablet); 
    }

    @include breakpoint($laptop) {
        @include rem-size(font-size, $large-size__laptop);
        @include rem-size(line-height, $large-leading__laptop);  
        @include rem-size(margin-bottom, $large-leading__laptop);  
    }

    @include breakpoint($desktop) {
        @include rem-size(font-size, $large-size__desktop);
        @include rem-size(line-height, $large-leading__desktop); 
        @include rem-size(margin-bottom, $large-leading__desktop);     
    }
}

h2 {
    @include rem-size(font-size, $medium-size);
    @include rem-size(line-height, $medium-leading); 
    @include rem-size(margin-bottom, $medium-leading/2); 
    
    @include breakpoint($tablet-portrait) {
        @include rem-size(font-size, $medium-size__tablet);
        @include rem-size(line-height, $medium-leading__tablet); 
        @include rem-size(margin-bottom, $medium-leading__tablet/2);
    }

    @include breakpoint($laptop) {
        @include rem-size(font-size, $medium-size__laptop);
        @include rem-size(line-height, $medium-leading__laptop); 
        @include rem-size(margin-bottom, $medium-leading__laptop/2);  
    }

    @include breakpoint($desktop) {
        @include rem-size(font-size, $medium-size__desktop);
        @include rem-size(line-height, $medium-leading__desktop); 
        @include rem-size(margin-bottom, $medium-leading__desktop/2);        
    }
}

h3 {
    @include rem-size(font-size, $xbase-size);
    @include rem-size(line-height, $xbase-leading); 
    @include rem-size(margin-bottom, $xbase-leading/2);

    @include breakpoint($tablet-portrait) {
        @include rem-size(font-size, $xbase-size__tablet);
        @include rem-size(line-height, $xbase-leading__tablet);   
        @include rem-size(margin-bottom, $xbase-leading__tablet/4);  
    }   

    @include breakpoint($laptop) {
        @include rem-size(font-size, $xbase-size__laptop);
        @include rem-size(line-height, $xbase-leading__laptop); 
        @include rem-size(margin-bottom, $xbase-leading__laptop/4);   
    }

    @include breakpoint($desktop) {
        @include rem-size(font-size, $xbase-size__desktop);
        @include rem-size(line-height, $xbase-leading__desktop);
        @include rem-size(margin-bottom, $xbase-leading__desktop/4); 
    }
}

h4 {
    @include rem-size(font-size, $base-size);
    @include rem-size(line-height, $base-leading); 
    @include rem-size(margin-bottom, $base-leading/2); 

    @include breakpoint($tablet-portrait) {
        @include rem-size(font-size, $base-size__tablet);
        @include rem-size(line-height, $base-leading__tablet); 
        @include rem-size(margin-bottom, $base-leading__tablet/2);     
    }  

    @include breakpoint($desktop) {
        @include rem-size(font-size, $base-size__laptop);
        @include rem-size(line-height, $base-leading__laptop);  
        @include rem-size(margin-bottom, $base-leading__laptop/2);  
    } 

    @include breakpoint($desktop) {
        @include rem-size(font-size, $base-size__desktop);
        @include rem-size(line-height, $base-leading__desktop);
        @include rem-size(margin-bottom, $base-leading__desktop/2); 
    }
}

p {  
    @include rem-size(font-size, $xbase-size);
    @include rem-size(line-height, $xbase-leading);
    @include rem-size(margin-bottom, $xbase-leading*1.5);
    color: $color-medium-grey;  
    font-family: $font-regular;    
    text-align:left;

    @include breakpoint($tablet-portrait) {
        @include rem-size(font-size, $xbase-size__tablet);
        @include rem-size(line-height, $xbase-leading__tablet);
        @include rem-size(margin-bottom, $xbase-leading__tablet*1.5);
    }

    @include breakpoint($laptop) {
        @include rem-size(font-size, $xbase-size__laptop);
        @include rem-size(line-height, $xbase-leading__laptop);  
        @include rem-size(margin-bottom, $xbase-leading__laptop*1.5); 
    }

    @include breakpoint($desktop) {
        @include rem-size(font-size, $xbase-size__desktop);
        @include rem-size(line-height, $xbase-leading__desktop);
        @include rem-size(margin-bottom, $xbase-leading__desktop*1.5);
    }     
}

small {
    @include rem-size(font-size, $small-size);
    @include rem-size(line-height, $small-leading); 
    @include rem-size(margin-bottom, $small-leading);

    @include breakpoint($tablet-portrait) { 
        @include rem-size(font-size, $small-size__tablet);
        @include rem-size(line-height, $small-leading__tablet);
        @include rem-size(margin-bottom, $small-leading__tablet);        
    } 

    @include breakpoint($laptop) {
        @include rem-size(font-size, $small-size__laptop);
        @include rem-size(line-height, $small-leading__laptop); 
        @include rem-size(margin-bottom, $small-leading__laptop);  
    }
        

    @include breakpoint($desktop) {
        @include rem-size(font-size, $small-size__desktop);
        @include rem-size(line-height, $small-leading__desktop); 
        @include rem-size(margin-bottom, $small-leading__desktop); 
    } 
}

b,
strong {
    font-family: $font-bold;
    font-weight: normal; 
}

i {
    font-family: $font-italic; 
    font-style: none; 
}

blockquote {
    @include rem-size(font-size, $medium-size);
    @include rem-size(line-height, $medium-leading);
    @include rem-size(padding-left, 10px);
    @include rem-size(margin-bottom, $base-leading*1.5);
    font-family: $font-regular;
    border-left:solid 1px $color-light-grey-5;      
      
    @include breakpoint($tablet-portrait) { 
        @include rem-size(font-size, $medium-size__tablet);
        @include rem-size(line-height, $medium-leading__tablet);        
        @include rem-size(padding-left, 15px);
        @include rem-size(padding-right, 15px);  
        @include rem-size(margin-bottom, $base-leading__tablet*1.5);
    } 

    @include breakpoint($laptop) {
        @include rem-size(font-size, $medium-size__laptop);
        @include rem-size(line-height, $medium-leading__laptop);  
        @include rem-size(margin-bottom, $base-leading__laptop*1.5); 
    }
        
    @include breakpoint($desktop) {
        @include rem-size(font-size, $medium-size__desktop);
        @include rem-size(line-height, $medium-leading__desktop); 
        @include rem-size(margin-bottom, $base-leading__desktop*1.5);  
        @include rem-size(padding-left, 20px);
        @include rem-size(padding-right, 20px);  
    } 
}   
figure {

    figcaption {
        font-family: $font-bold;
        color: $color-medium-grey; 
        @include rem-size(font-size, $xsmall-size);
        @include rem-size(line-height, $xsmall-leading); 
        
        @include breakpoint($tablet-portrait) {
            @include rem-size(font-size, $xsmall-size__tablet);
            @include rem-size(line-height, $xsmall-leading__tablet);     
        }

        @include breakpoint($laptop) {
            @include rem-size(font-size, $xsmall-size__laptop);
            @include rem-size(line-height, $xsmall-leading__laptop);   
        }

        @include breakpoint($desktop) {
            @include rem-size(font-size, $xsmall-size__desktop);
            @include rem-size(line-height, $xsmall-leading__desktop);     
        }
    }
}   