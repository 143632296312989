// search

// .search {		
// 	float:right;
// 	display: block;
// 	width:48px;
// 	height: 48px;
// 	line-height: 36px;
// 	@include rem-size(padding, 10px);

// 	text-align: center;
	
// 	&__link {
// 		//display: block;
// 		color: $color-black;
// 		color: $color-dark-grey-3;
// 		border:solid 1px $color-white; 
// 		background: yellow;
// 		position:relative;
// 	top:-18px;
// 	}
// }

.sb-search {
	position: absolute;
	right: 23px;
	top:-1px;
	width: 0%;
	@include rem-size(width, 34px);
	@include rem-size(height, 34px);
	float: right;
	overflow: hidden;
	-webkit-transition: width 0.3s;
	-moz-transition: width 0.3s;
	transition: width 0.3s;
	-webkit-backface-visibility: hidden;

	@include breakpoint($laptop) {
		right:0;
	}
}

.sb-search-input {
	position: absolute;
	top: 0;
	right: 0;
	border: none;
	outline: none;
	background: #fff;
	border:1px $color-light-grey-4  solid;
	width: 55%;
	@include rem-size(height, 34px);
	margin: 0;
	z-index: 10;
	@include rem-size(padding, 0 42px 0 6px); // Placering för knapp
	font-family: inherit;
	opacity: 0;
	 -webkit-transition: opacity 0.3s;

	@include breakpoint($tablet-portrait) {
		width: 80%;
	}
}

.sb-search-open .sb-search-input {
	opacity: 1;
}

input[type="search"].sb-search-input {
	-webkit-appearance: none;
	-webkit-border-radius: 0px;
}

.sb-search-input:-moz-placeholder,
.sb-search-input::-webkit-input-placeholder,
.sb-search-input:-ms-input-placeholder
.sb-search-input:input-placeholder {
	color: $color-light-grey-2;
}

.sb-icon-search,
.sb-search-submit  {
	@include rem-size(height, 34px);
	@include rem-size(width, 34px);
	display: block;
	position: absolute;
	right: 0;
	top: 0;
	padding: 0;
	margin: 0;
	color: $color-black;
	@include rem-size(line-height, 34px);
	text-align: center;
	cursor: pointer;
}

.sb-search-submit {
	background: #fff; /* IE needs this */
	-ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)"; /* IE 8 */
    filter: alpha(opacity=0); /* IE 5-7 */
    opacity: 0;
	color: transparent;
	border: none;
	outline: none;
	z-index: -1;
}

.sb-icon-search {
	color: $color-medium-grey;
	border:1px $color-light-grey-4 solid;
	z-index: 90;
	font-size: 14px;
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
}

.sb-icon-search:before {
	//content: "\e000";
}

/* Open state */
.sb-search.sb-search-open,
.no-js .sb-search {
	width: 300px;
}

.sb-search.sb-search-open .sb-icon-search,
.no-js .sb-search .sb-icon-search {
//	background: #da6d0d;
//	color: #fff;
	z-index: 11;
}

.sb-search.sb-search-open .sb-search-submit,
.no-js .sb-search .sb-search-submit {
	z-index: 90;
}