// related

.related {
	@include rem-size(padding, 15px 5px);
	@include rem-size(font-size, $xsmall-size__desktop);
    @include rem-size(line-height, $xsmall-leading__desktop);
	border-top: 1px solid $color-light-grey-5;	

	.light {
		margin: 0;
	}

	a,
	b {
		color: $color-black;
	}
}