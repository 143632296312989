// list no bullets

%list-no-bullets {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

// background size cover 

%background-size-cover {
    background-position: center center; 
    background-repeat: no-repeat;  
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}    