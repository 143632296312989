// content

main[role="main"] {
	display: block;
	
	@include breakpoint($laptop) {
		@include rem-size(padding-top, 140px);
		width:100%;
	}

	@include breakpoint($desktop) {
		@include rem-size(padding-top, 140px);
		
	}
}
	
.placeholder {
    @include clearfix;
}

.placeholder-main {
	@include clearfix;
	@include rem-size(padding-top, 20px);

	@include breakpoint($tablet-portrait) {
		@include rem-size(padding-top, 40px);
	}

	@include breakpoint($laptop) {
		@include rem-size(padding-top, 60px);
	}
}

 
