// header

.header {		
	@include rem-size(padding, 20px 0);	
	@include clearfix;
	background: $color-white;
	width: 100%;
	z-index: 10;

	@include breakpoint($laptop, 12) {
		//@include rem-size(padding, 25px 0);
		padding:0;
		position: fixed;
		top:0;
		left:0;
		height:140px;
	}

	.logo {
		@include span(2 of 4);	

		@include susy-breakpoint($tablet-portrait, 6) {
			@include span(3);		
		}

		@include susy-breakpoint($laptop, 12) {
			@include span(3);		
		}

		@include susy-breakpoint($desktop, 12) {
			@include span(3);		
		}
	}
	
	&__right {
		@include clearfix;	
		@include span(2 of 4 last);
		@include rem-size(margin-top, 5px);
	//	position: relative;
		
		@include susy-breakpoint($tablet-portrait, 6) {
			@include span(3 last);		
		}

		@include susy-breakpoint($laptop, 12) {
			@include span(9 last);	
			@include rem-size(margin-top, 40px);	
		}

		@include susy-breakpoint($desktop, 12) {
			@include span(9 last);			
		}

		.main-nav {
			display: none;

			@include susy-breakpoint($laptop, 12) {
				@include span(8 at 3 isolate);
				@include rem-size(margin-top, 10px);	
				display: block;
			}

			@include susy-breakpoint($desktop, 12) {
				@include span(8 at 3 isolate);	
			}
		}	

		&__right {
			@include clearfix;
			float:right;
			position: absolute;
			right: 0;
		}
	
		.help-nav {
			@include clearfix;
			float:right;	
		}

		.search {
			float:right;
		}
	}
}

.menu-button {
	position: absolute;
	top: -6px;
	right: -5px;

	@include breakpoint($laptop, 12) {
		display: none;
	}

	&__link {
		display: block;
	}	
}

