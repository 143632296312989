// logo

.logo {		
	width: auto;
	display: inline;
	display: block;
	margin:0 auto;
	@include rem-size(padding-left, 6px);

	@include breakpoint($laptop) {
		@include rem-size(padding-left, 32px);
	}


	&__img {
		height: 60px;
		display: block;

		@include susy-breakpoint($tablet-portrait) {
			height: 65px;
		}

		@include susy-breakpoint($laptop) {
			height: 90px;
		}
	}
}
	