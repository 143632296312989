// primary content

.primary-content {
	@include clearfix;

	@include susy-breakpoint($tablet-portrait, 6) {
		@include rem-size(padding-bottom, 40px);
		@include span(3);
	}

	@include susy-breakpoint($laptop, 12) {
		@include rem-size(padding-bottom, 60px);
		@include span(7);
	}

	&--right {

		@include susy-breakpoint($tablet-portrait, 6) {
			@include span(3 last);		
		}

		@include susy-breakpoint($laptop, 12) {
			@include span(7 last);
		}
	}
	
	&--narrow {

		@include susy-breakpoint($tablet-portrait, 6) {
			@include span(3);
		}

		@include susy-breakpoint($laptop, 12) {
			@include span(5);
		}

	}
}

// sidebar


.sidebar {
	@include rem-size(margin-top, 40px);

	@include susy-breakpoint($tablet-portrait, 6) {
		@include span(3 last);
		margin-top: 0;
		padding-left:gutter(12);		
	}

	@include susy-breakpoint($laptop, 12) {
		@include span(5 last);
	}

	&--left {

		@include susy-breakpoint($tablet-portrait, 6) {
			@include span(3);
			padding-left: 0;
			padding-right:gutter(12);		
		}

		@include susy-breakpoint($laptop, 12) {
			@include span(5);
		}
	}

	&--wide {
		
		@include susy-breakpoint($tablet-portrait, 6) {
			@include span(3 last);
		}

		@include susy-breakpoint($laptop, 12) {
			@include span(7 last);
		}
	}
}
