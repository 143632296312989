// main-nav

.main-nav {			
	
	&__list {					
		@extend %list-no-bullets;	
		@include clearfix;
		text-transform: uppercase;	
		float:right;	

		&__item {	
			@include rem-size(font-size, $xsmall-size__laptop);
    		@include rem-size(line-height, $xsmall-leading__laptop);
    		@include rem-size(margin-right, 20px);  
			font-family: $font-regular;
			float: left;
			
			&:first-child {
				padding-left: 0;
			}			

			&:last-child {
				margin-right: 0;
			}			
		}
	}

	&__link {
		color: $color-medium-grey;
		
		&:hover {
			color: $color-black;
			text-decoration: none;
		}       
	}
}		
