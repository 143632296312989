// placeholders

%icon {
	padding-right: 20px;
}

// menu

.icon-menu {
    @extend %icon;  
    @include rem-size(height, 43px);
    @include rem-size(width, 43px);
    display: inline-block;
    background: url(/img/svg/menu.svg) no-repeat top left;

    &.no-svg {
        background: url(/img/png/menu.png) no-repeat top left;
    }
} 










