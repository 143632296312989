// usp

.usp {
    @extend %background-size-cover;
    @include rem-size(height, 400px);
    width: 100%;

    @include breakpoint($mobile-horizontal) {
		@include rem-size(height, 500px);
		height: 50vh;
	}    

	@include breakpoint($tablet-portrait) {
		@include rem-size(height, 600px);
		height: 50vh;

	}

	@include breakpoint($laptop) {
		@include rem-size(height, 800px);
		height: 50vh;
	}

	@include breakpoint($desktop) {
		@include rem-size(height, 1000px);
		height: 50vh;
	}		

	&__content {
		display: table-cell;
		height:100%;
		width: 100%;
		vertical-align: middle;
	}
	
	&__headline {
	    @include rem-size(font-size, $xlarge-size);
	    @include rem-size(line-height, $xlarge-leading);
	    @include rem-size(margin-top, 20px); 
	    @include rem-size(margin-bottom, $xlarge-leading/2); 
	    font-family: $font-regular;
	    color: $color-white;
	    text-align: center;
	    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.4);

	    @include breakpoint($tablet-portrait) {
	        @include rem-size(font-size, $xlarge-size__tablet);
	        @include rem-size(line-height, $xlarge-leading__tablet);     
	        @include rem-size(margin-bottom, $xlarge-leading__tablet/2); 
	    }

	    @include breakpoint($laptop) {
	        @include rem-size(font-size, $xlarge-size__laptop);
	        @include rem-size(line-height, $xlarge-leading__laptop);  
	        @include rem-size(margin-bottom, $xlarge-leading__laptop/2);  
	    }

	    @include breakpoint($desktop) {
	        @include rem-size(font-size, $xlarge-size__desktop);
	        @include rem-size(line-height, $xlarge-leading__desktop); 
	        @include rem-size(margin-bottom, $xlarge-leading__desktop/2);     
	    }
	}

	&__paragraph {
		@include rem-size(font-size, $small-size);
    	@include rem-size(line-height, $small-leading);
    	@include rem-size(margin-bottom, 16px);
    	text-transform: uppercase;
    	color: $color-white;  
    	text-align: center; 

    	@include breakpoint($tablet-portrait) {
			@include rem-size(font-size, $small-size__tablet);
    		@include rem-size(line-height, $small-leading__tablet);
    	} 

    	@include breakpoint($laptop) {
			@include rem-size(font-size, $small-size__laptop);
    		@include rem-size(line-height, $small-leading__laptop);
    	}

    	@include breakpoint($desktop) {
			@include rem-size(font-size, $small-size__desktop);
    		@include rem-size(line-height, $small-leading__desktop);
    	}
	}

	.btn {
		@include rem-size(margin, 10px 20px);
		display: block;

		@include breakpoint($tablet-portrait) {
			@include rem-size(margin, 0 10px);
			display: inline-block;
		}
		
		&:last-child {
			margin-bottom: 0;
		}
	}
}

// usp page

.usp-page {
	img {
		display: block;
	}
}