embed, object, iframe, img {
	max-width:100%;	
}

img {
	border: none;
	_width: 100%;
	height: auto;
	width: auto\9;
	height: auto;
}
