// news overview

.news-overview {
	@include clearfix;
	@include rem-size(margin-top, 6px);

	@include susy-breakpoint($desktop, 12) {
		@include rem-size(margin-top, 12px);
	}

	.objects-l {
		.small-headline {
			@include rem-size(margin, 12px 10px 0 10px); //override

			@include breakpoint($laptop) {
				@include rem-size(margin, 25px 10px 0 10px); //override
			}
		}
	}
}

// news header

.news-header {
	@include rem-size(margin-bottom, 20px);

	@include susy-breakpoint($tablet-portrait, 6) {
		@include rem-size(margin-bottom, 40px);
		padding-left: span(3) + gutter(6);
	} 

	@include susy-breakpoint($laptop, 12) {
		@include rem-size(margin-bottom, 60px);
		padding-left: span(5) + gutter(12);
	} 

	.large-headline {
		margin-bottom: 0;
		text-transform: uppercase;
	}

	.info-text {
		margin-bottom: 0;
	}
}

// news primary content

.news-primary-content {	
	@include rem-size(margin-bottom, 20px);

	@include susy-breakpoint($tablet-portrait, 6) {
		@include rem-size(margin-bottom, 40px);			
	}

	@include susy-breakpoint($laptop, 12) {
		@include rem-size(margin-bottom, 60px);	
	}
}

// news nav {

.news-nav {			
	@include rem-size(margin-bottom, 20px);

	@include breakpoint($tablet-portrait) {
		@include rem-size(margin-bottom, 40px);
	}

	@include breakpoint($laptop) {
		@include rem-size(margin-bottom, 60px);
	}

	.small-headline {
		@include rem-size(margin, 10px 10px 0 10px);
		min-height: 20px; 
        max-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
		float: left;
		width: 70%;

		@include susy-breakpoint($tablet-portrait $tablet-horizontal) {
			width: 60%;
		}

		@include breakpoint($laptop) {
			@include rem-size(margin, 25px 10px 0 10px);
		}
	}

	&__list {					
		@extend %list-no-bullets;	

		&__item {	
			@include clearfix;
			@extend small;
			@include rem-size(padding, 10px 5px);
    		border-bottom: 1px solid $color-light-grey-5;
			font-family: $font-bold;	

			@include breakpoint($laptop) {
				@include rem-size(padding, 20px 5px);
			}		
		}
	}

	&__link {
		display: block;
		color: $color-black;
				
		&:hover {
			color: $color-black;
		}       
	}

	&__footer {
		.small-headline {
			@include rem-size(margin, 25px 10px 25px 5px);
			float: none;
			width: 100%;

			@include susy-breakpoint($tablet-portrait $tablet-horizontal) {
				width: 100%;
			}
		}
	}
}

