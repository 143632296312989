// Defaults
// ==========

$griddebug: false;

// Media query device constants
// ============================

$mobile                  	: 320px;
$mobile-horizontal       	: 480px;
$tablet                  	: 768px 1024px;
$tablet-portrait         	: 768px;
$tablet-horizontal       	: 1024px;
$laptop                 	: 1200px;
$desktop	            	: 1600px;
$desktop-wide      			: 2000px;
$desktop-extra-wide			: 2560px;

// Colors
// ======

$color-black			    : #000;
$color-white			  	: #fff;

$color-grey         		: #7C7C7C;  
$color-grey-2 				: #8F8F8F;
$color-light-grey		    : #887777; 
$color-light-grey-2		    : #979797;
$color-light-grey-3 		: #F1F1F2; 
$color-light-grey-4 		: #979797; // borders
$color-light-grey-5 		: #E4E4E4;  
$color-light-grey-6 		: #ccc;
$color-medium-grey 			: #4E4E4E; // global nav
$color-medium-grey-2 		: #9D9D9D; // 
$color-dark-grey            : #535353; // handmade
$color-dark-grey-2          : #272727; 
$color-dark-grey-3   		: #1A1919; // help nav, headlines i.e.

$color-main				   	: #213B8F; // blue

$color-warning				: #ff0000; // alerts
$color-action				: #0000ff; // links

// Typography
// ==========

$tiny-size 					: 11px; 
$tiny-leading 				: 15px;

$xsmall-size 				: 11px; 
$xsmall-leading 			: 15px;

$small-size 				: 12px; 
$small-leading 				: 17px;

$xbase-size		     		: 14px;
$xbase-leading			   	: 19px;

$base-size     				: 16px;
$base-leading   			: 21px;

$medium-size 				: 18px; 
$medium-leading 			: 26px;

$xmedium-size				: 19px; 
$xmedium-leading		 	: 25px;

$xxmedium-size				: 20px; 
$xxmedium-leading		 	: 26px;

$large-size 				: 22px; 
$large-leading 				: 28px;

$xlarge-size           		: 30px;   
$xlarge-leading        		: 45px;

// Tablet 

$tiny-size__tablet			: 11px; 
$tiny-leading__tablet		: 15px;

$xsmall-size__tablet 		: 12px; 
$xsmall-leading__tablet 	: 18px;

$small-size__tablet 		: 14px; 
$small-leading__tablet 		: 19px;

$xbase-size__tablet     	: 16px;
$xbase-leading__tablet   	: 21px;

$base-size__tablet     		: 18px;
$base-leading__tablet   	: 24px;

$medium-size__tablet 		: 18px; 
$medium-leading__tablet 	: 23px;

$xmedium-size__tablet		: 22px; 
$xmedium-leading__tablet 	: 28px;

$xxmedium-size__tablet		: 26px; 
$xxmedium-leading__tablet 	: 33px;

$large-size__tablet 		: 28px; 
$large-leading__tablet 		: 35px;

$xlarge-size__tablet    	: 40px;   
$xlarge-leading__tablet 	: 60px;

// Laptop 	

$tiny-size__laptop			: 12px; 
$tiny-leading__laptop		: 16px;

$xsmall-size__laptop		: 13px; 
$xsmall-leading__laptop		: 17px;

$small-size__laptop 		: 14px; 
$small-leading__laptop		: 19px;

$xbase-size__laptop     	: 16px;
$xbase-leading__laptop   	: 21px;

$base-size__laptop	     	: 18px;
$base-leading__laptop	  	: 28px;

$medium-size__laptop		: 21px; 
$medium-leading__laptop 	: 30px;

$xmedium-size__laptop		: 28px; 
$xmedium-leading__laptop 	: 35px;

$xxmedium-size__laptop		: 32px; 
$xxmedium-leading__laptop 	: 40px;

$large-size__laptop 		: 40px; 
$large-leading__laptop 		: 50px;

$xlarge-size__laptop    	: 72px;   
$xlarge-leading__laptop 	: 86px;

// Desktop 	

$tiny-size__desktop			: 12px; 
$tiny-leading__desktop		: 17px;

$xsmall-size__desktop 		: 13px; 
$xsmall-leading__desktop	: 18px;

$small-size__desktop 		: 14px; 
$small-leading__desktop		: 19px;

$xbase-size__desktop     	: 16px;
$xbase-leading__desktop   	: 21px;

$base-size__desktop     	: 21px;
$base-leading__desktop   	: 28px;

$medium-size__desktop		: 24px; 
$medium-leading__desktop 	: 31px;

$xmedium-size__desktop		: 32px; 
$xmedium-leading__desktop 	: 36px;

$xxmedium-size__desktop		: 36px; 
$xxmedium-leading__desktop 	: 48px;

$large-size__desktop 		: 48px; 
$large-leading__desktop 	: 60px;

$xlarge-size__desktop    	: 90px;   
$xlarge-leading__desktop 	: 113px;

$base-line-height			: 6px;	// Baseline grid
$rhythm-unit				: 'rem'; // Rem with px fallback

//@include establish-baseline;		// Set up everything

body { 
	font-size:62.5%; 
}

// Fonts

$font-regular				: "Myriad W01 Regular", sans-serif;
$font-bold					: "Myriad W01 Bd", sans-serif;
$font-light					: "Myriad W01 Lt", sans-serif;
$font-italic				: "Myriad W01 It", sans-serif;