// Reset
* {
	margin: 0;
	padding: 0;
}


// Box sizing
* {
	&,
	&:before,
	&:after {
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
	}
}

html {
	@include rem-size(line-height, 24px);	// Adjust leading to 24px
	-webkit-text-size-adjust: 100%; //Preserve HTML font-size when orientation changes from portrait to landscape
	-ms-text-size-adjust: 100%;

}

body,
html {
    height: 100%
}

body {
	font-family: $font-regular;
}

video {
	object-fit: fill;
}

