*, *:before, *:after {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box;    /* Firefox, other Gecko */
  -box-sizing: border-box;         /* Opera/IE 8+ */
  -ms-box-sizing: border-box;         /* Opera/IE 8+ */
  box-sizing: border-box;         
 }

body {
  position: relative;
  overflow-x: hidden;
}

$susy: (
    columns: 12,
    gutters: (1/2),
    math: fluid,
    gutter-position: after,
    debug: (
        image: hide,
        color: rgba(blue, .1),
        output: show,
        toggle: top right,
        ),
    );

$small: (
    columns: 4,
    gutters: (1/2),
    math: fluid,
    debug: (
        image: hide,
        color: rgba(blue, .08),
        output: show,
        toggle: top right,
        ),
    );

$medium: (
    columns: 6,
    gutters: (1/2),
    math: fluid,
    debug: (
        image: hide,
        color: rgba(green, .08),
        output: show,
        toggle: top right,
        ),
    );

$large: (
    columns: 12,
    gutters: (1/2),
    math: fluid,
    debug: (
        image: hide,
        color: rgba(black, .08),
        output: show,
        toggle: top right,
        ),
    );


%container {

    /**
     * Change settings per context/breakpoint. A few different options:
     * https://github.com/ericam/susy/issues/256
     */

    @include container(76em);

    @include breakpoint($mobile) {

        @if $griddebug == true {
            @include container($small 95% show-columns);
        } @else {
          @include container($small 95%);
        }

    }

    @include breakpoint($tablet-portrait) {

        @if $griddebug == true {
            @include container($medium 95% show-columns);
        } @else {
          @include container($medium 95%);
        }

     }

    @include breakpoint($laptop) {

        @if $griddebug == true {
            @include container($large 85% show-columns);
        } @else {
          @include container($large 85%);
        }

     }

     @include breakpoint($desktop) {
        
         @if $griddebug == true {
             @include container($large 85% show-columns);
         } @else {
           @include container($large 85%);
         }

      }

      @include breakpoint($desktop-wide) {
        
         @if $griddebug == true {
             @include container($large 65% show-columns);
         } @else {
           @include container($large 65%);
         }

      }

}

/**
 * Container
 */
 
.c {
  @extend %container;
  @include clearfix;  
}

@if $griddebug == true {
  html {
    background-image: url(http://basehold.it/i/6/ff0000);
  }
}

@if $griddebug == true {
  .c {
    @include breakpoint($mobile) {
      &:before {
        content: 'Mobile portrait 320px: ☳ 4 cols ⇿ 94%';
        position: fixed;
        top: 0;
        right: 0;
        z-index: 10;
        background-color: DarkSeaGreen;
        color: white;
        font-size: .8em;
        padding: .4em 1em;
        font-family: helvetica;
      }
    }
    @include breakpoint($mobile-horizontal) {
      &:before {
        content: 'Mobile horizontal 480px: ☳ 4 cols ⇿ 94%';
        position: fixed;
        top: 0;
        right: 0;
        z-index: 10;
        background-color: coral;
        color: white;
        font-size: .8em;
        padding: .4em 1em;
        font-family: helvetica;
      }
    }
    @include breakpoint($tablet-portrait) {
      &:before {
        content: 'Tablet portrait 768px: ☳ 6 cols ⇿ 94%';
        position: fixed;
        top: 0;
        right: 0;
        z-index: 10;
        background-color: olive;
        color: white;
        font-size: .8em;
        padding: .4em 1em;
        font-family: helvetica;
      }
    }
    @include breakpoint($tablet-horizontal) {
      &:before {
        content: 'Tablet 1024px: ☳ 6 cols ⇿ 94%';
        position: fixed;
        top: 0;
        right: 0;
        z-index: 10;
        background-color: green;
        color: white;
        font-size: .8em;
        padding: .4em 1em;
        font-family: helvetica;
      }
    }
    @include breakpoint($desktop) {
      &:before {
        content: 'Desktop > 1170px: ☳ 12 cols ⇿ 76em';
        position: fixed;
        top: 0;
        right: 0;
        z-index: 10;
        background-color: blue;
        color: white;
        font-size: .8em;
        padding: .4em 1em;
        font-family: helvetica;
      }
    }
    @include breakpoint($desktop-wide) {
      &:before {
        content: 'Desktop wide > 1500px: ☳ 12 cols ⇿ 76em';
        position: fixed;
        top: 0;
        right: 0;
        z-index: 10;
        background-color: red;
        color: white;
        font-size: .8em;
        padding: .4em 1em;
        font-family: helvetica;
      }
    }
    @include breakpoint($desktop-extra-wide) {
      &:before {
        content: 'Desktop wide > 1800px: ☳ 12 cols ⇿ 86em';
        position: fixed;
        top: 0;
        right: 0;
        z-index: 10;
        background-color: LightSalmon;
        color: white;
        font-size: .8em;
        padding: .4em 1em;
        font-family: helvetica;
      }
    }
  }
}






