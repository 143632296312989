	// welcome

.welcome {
	@include rem-size(margin-bottom, 20px);
	//padding-left: span(1);
	//padding-right: span(1);
	
	@include susy-breakpoint($tablet-portrait, 6) {
		@include rem-size(margin-bottom, 40px);
		//padding-left: span(1);
		//padding-right: span(1);
	}

	@include susy-breakpoint($laptop, 12) {
		@include rem-size(margin-bottom, 80px);
		//padding-left: span(2 wide);
		//padding-right: span(2 wide);
	}
}

.home .objects-l .small-headline {
	@include rem-size(margin, 12px 10px 0 10px); //override

	@include breakpoint($laptop) {
		@include rem-size(margin, 25px 10px 0 10px); //override
	}
}

// spots home

.spots-home {
	@include clearfix;

	&__item {
		@include clearfix;  
		margin-bottom: gutter(12); 

		@include breakpoint($tablet-portrait) {
			@include span(6 of 12);

			&:last-child {
				@include last;
			}
		}

		@include breakpoint($tablet-horizontal) {
			//@include rem-size(height, 300px);
		}

		@include breakpoint($laptop) {
			//@include rem-size(height, 500px);			
		}	
		
		@include breakpoint($desktop) {
			//@include rem-size(height, 600px);
		}
	
		&--hover {
			img,
			a {
				display: block;
			}

			a {			
				position:relative;
				z-index:1;

				&:hover:after {
					opacity: 0.2;
				}
			
				&:after {
					position:absolute;
					top:0;
					bottom:0;
					left:0;
					right:0;
					display: block;
					content:"";
					opacity:0;
					-webkit-transition: opacity 0.5s ease;
					-moz-transition: opacity 0.5s ease;
					-o-transition: opacity 0.5s ease;
					transition: opacity 0.5s ease;
					background: #000;
					z-index:2;
				}			
			}
		}
	}	

	a {
		display: block;
	}

	img {
		display: block;
	}

	.small-headline {
		@include rem-size(margin, 25px 10px 0 0);
		min-height: 20px; 
        max-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
		float: left;
		width: auto;
		width: 80%;
		
		&--hover {
			min-height: 30px; 
	        max-height: 30px;
	        padding-top: 10px;
			margin-bottom: 0;
			background:#fff;
			position:relative;
			z-index: 3;
	        margin-bottom:0;
	        padding-bottom:0;
	        overflow: hidden;
	        display: block;
	        text-overflow: ellipsis;
	        white-space: nowrap;
		}
	}
}