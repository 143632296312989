// breadcrumbs

.breadcrumbs {
	@include rem-size(margin-top, 20px);
	@include rem-size(margin-bottom, 20px);
	
	&__paragraph {
		@include rem-size(font-size, $tiny-size);
	    @include rem-size(line-height, $tiny-leading); 
		color: $color-grey-2; 
		text-transform: uppercase;
		margin: 0;

	    @include breakpoint($tablet-portrait) { 
	        @include rem-size(font-size, $tiny-size__tablet);
	        @include rem-size(line-height, $tiny-leading__tablet);        
	    } 

	    @include breakpoint($laptop) {
	        @include rem-size(font-size, $tiny-size__laptop);
	        @include rem-size(line-height, $tiny-leading__laptop);   
	    }
	        

	    @include breakpoint($desktop) {
	        @include rem-size(font-size, $tiny-size__desktop);
	        @include rem-size(line-height, $tiny-leading__desktop);  
	    }
	}

	&__link {
		color: $color-grey-2; 

		&:after {
			@include rem-size(margin-right, 10px);
			@include rem-size(padding-left, 10px);
			content: "/";			
		
			&:first-child {
				content: "";
			}
		}

		&:hover {
			color: $color-grey-2; 
			text-decoration: none;
		}
	}
}