// objects large

.objects-l {
	@include clearfix;

	&__item {
		//@include rem-size(height, 200px);  
		@include clearfix;	
		margin-bottom: gutter(6);

		@include breakpoint($mobile-horizontal) {
			//@include rem-size(height, 300px);
		}    

		@include breakpoint($tablet-portrait) {
			//@include rem-size(height, 400px);
			@include gallery(2 of 6);
			margin-bottom: gutter(9);
		}

		@include breakpoint($tablet-horizontal) {
			//@include rem-size(height, 300px);
		}

		@include breakpoint($laptop) {
			//@include rem-size(height, 500px);
			@include gallery(4 of 12);	
			margin-bottom: gutter(6);		
		}	
		
		@include breakpoint($desktop) {
			//@include rem-size(height, 600px);
		}
	}

	img,
	a {
		display: block;
	}

	a {			
		position:relative;
		z-index:1;

		&:hover:after {
			opacity: 0.2;
		}
	
		&:after {
			position:absolute;
			top:0;
			bottom:0;
			left:0;
			right:0;
			display: block;
			content:"";
			opacity:0;
			-webkit-transition: opacity 0.5s ease;
			-moz-transition: opacity 0.5s ease;
			-o-transition: opacity 0.5s ease;
			transition: opacity 0.5s ease;
			background: #000;
			z-index:2;
		}			
	}	

	.small-headline {
		min-height: 30px; 
        max-height: 30px;
        margin-bottom:0;
        padding-top: 10px;
		margin-bottom: 0;
		background:#fff;
		position:relative;
		z-index: 3;
        padding-bottom:0;
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
	
		&__wnumbers {
			float: left;
			min-height: 23px; 
        	max-height: 23px;
        	margin-bottom:0;
        	padding-top: 0;
			width: 70%;

			@include susy-breakpoint($tablet-portrait $tablet-horizontal) {
				width: 60%;
			}
		}
	}	
}

.home .news__item {
	margin-bottom: 0!important;
}