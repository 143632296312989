// share

.share {
	@include rem-size(padding, 15px 5px);
	@include rem-size(font-size, $xsmall-size__desktop);
    @include rem-size(line-height, $xsmall-leading__desktop);
	border-top: 1px solid $color-light-grey-5;	
	border-bottom: 1px solid $color-light-grey-5;

	.light {
		margin: 0;
	}

	a {
		color: $color-black;
	}

	i {
		@include rem-size(margin-left, 15px);
	
		&:hover {
			color: $color-medium-grey-2;
	        text-decoration: none; 
	        -webkit-transition: opacity 0.5s ease;
	        -moz-transition: opacity 0.5s ease;
	        -o-transition: opacity 0.5s ease;
	        transition: opacity 0.5s ease;
	        cursor: pointer;
		}
	}
}