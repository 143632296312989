// help-nav

.help-nav {			
	position: absolute;
	top:35px;
	right: 25px;
	z-index:99999;

	@include breakpoint($laptop, 12) {
		top:71px;
		right: 45px;
	}

	&__list {					
		@extend %list-no-bullets;	
		@include clearfix;
		text-transform: uppercase;	
		@include rem-size(padding-right, 53px);

		@include breakpoint($laptop) {
			@include rem-size(padding-right, 33px);
		}

		// &:last-child {
		// 		@include rem-size(margin-right, 160px);

		// 		@include breakpoint($tablet-portrait) {
		// 			@include rem-size(margin-left, 40px);
		// 		}
		// 	}		

		&__item {	
			@include rem-size(font-size, $tiny-size__desktop);
    		@include rem-size(line-height, $tiny-leading__desktop);
    		@include rem-size(margin-right, 5px);
    		font-family: $font-regular;
			float: left;
	
			span {
				display: none; 

				@include breakpoint($tablet-portrait) {
					display: inline-block;
				}	
			}

				
		}
	}

	&__link {
		@include rem-size(padding, 9px);
		@include rem-size(width, 32px);
		@include rem-size(height, 32px);
		@include rem-size(line-height, 32px);
		color: $color-dark-grey-3;
		border:solid 1px $color-white; 
		
		&:hover {
			color: $color-dark-grey-3;
			text-decoration: none;
		}  

		&__active,
		&:hover {
			border:solid 1px $color-light-grey-4; 
		}     
	}
}		
