	// numbers

.numbers {			
	@include rem-size(height, 36px);
	@include rem-size(width, 56px);
	@include rem-size(font-size, $small-size);
    @include rem-size(line-height, $small-leading);
	display:inline-block;
	margin: 0;
	float: left;
	color:  $color-light-grey-4;
	position: relative;

	&:after {
		content: "";
		position:absolute;
		left:50%;
		width:1px;
		height: 100%;
		display: inline-block;
		border-left: solid 1px $color-light-grey-4;
	}

    @include breakpoint($tablet-portrait) {
        @include rem-size(font-size, $small-size__tablet);
        @include rem-size(line-height, $small-leading__tablet);    	
    }

    @include breakpoint($laptop) {
        @include rem-size(font-size, $small-size__laptop);
        @include rem-size(line-height, $small-leading__laptop);
    	@include rem-size(height, 60px);
		@include rem-size(width, 60px);
    }

    @include breakpoint($desktop) {
        @include rem-size(font-size, $small-size__desktop);
        @include rem-size(line-height, $small-leading__desktop);
   	}

	&__small {
		@include rem-size(height, 36px);
		width: 100%;
		@include rem-size(font-size, $xsmall-size);
    	@include rem-size(line-height, $xsmall-leading);

		.numbers__first {
			position: absolute;
			top:5px;
			left:5px;
		}

		.numbers__second {
			position: absolute;
			bottom:5px;
			right:5px;
		}
	
		&:after {
			content: "";
			position:absolute;
			left:50%;
			width:1px;
			height: 100%;
			display: inline-block;
			border-left: none!important;
		}
	}

	&__first {
		position: absolute;
		top:5px;
		left:10px;

		@include breakpoint($laptop) {
			top: 10px;
		}
	}

	&__second {
		position: absolute;
		bottom:0;
		right:10px;

		@include breakpoint($laptop) {
			bottom:10px;
		}
	}	 
}