// usp page

.usp-page {
    @extend %background-size-cover;
    background-position: center left;
    @include rem-size(height, 200px);
    width: 100%;
    display: table;

    @include breakpoint($mobile-horizontal) {
		@include rem-size(height, 250px);
		background-position: center center;
		height: 50vh;
	}    

	@include breakpoint($tablet-portrait) {
		@include rem-size(height, 300px);
		height: 50vh;

	}

	@include breakpoint($laptop) {
		@include rem-size(height, 400px);
		height: 50vh;
	}

	@include breakpoint($desktop) {
		@include rem-size(height, 500px);
		height: 50vh;
	}		

	&__content {
		padding-left: 7.5%;	
		padding-right: span(1);
		display: table-cell;		
		height:100%;
		vertical-align: middle;

		@include breakpoint($mobile-horizontal) {
			padding-right: span(7);	
		}

		@include breakpoint($desktop-wide) {
			padding-left: 17.5%;	
		}
	}
	
	&__headline {
	    font-family: $font-light;
	    text-align: left;

		&--neg {
			color: $color-white;
		}
	}

	&__paragraph {
		@include rem-size(font-size, $small-size);
    	@include rem-size(line-height, $small-leading);   
    	text-align: left; 	

    	@include susy-breakpoint($tablet-portrait) {
			@include rem-size(font-size, $small-size__tablet);
    		@include rem-size(line-height, $small-leading__tablet);
    	} 

    	@include susy-breakpoint($laptop) {
			@include rem-size(font-size, $small-size__laptop);
    		@include rem-size(line-height, $small-leading__laptop);
    	}

    	@include susy-breakpoint($desktop) {
			@include rem-size(font-size, $small-size__desktop);
    		@include rem-size(line-height, $small-leading__desktop);
    	}

    	&--neg {
    		color: $color-white;  
    	}
		
		&__narrow {
			@include rem-size(margin-top, $large-leading); 

			@include breakpoint($mobile-horizontal) {
				padding-right: span(7);
			}

			@include breakpoint($tablet-portrait) {   
		        @include rem-size(margin-top, $large-leading__tablet); 
		    }

		    @include breakpoint($laptop) { 
		        @include rem-size(margin-top, $large-leading__laptop);  
		    }

		    @include breakpoint($desktop) {
		        @include rem-size(margin-top, $large-leading__desktop);     
		    }
		}
	}

	&__no-img {	
		@include rem-size(padding-top, $large-leading); 
		height: auto;

	    @include breakpoint($tablet-portrait) {
	        @include rem-size(padding-top, $large-leading__tablet); 
	    }

	    @include breakpoint($laptop) {
	        @include rem-size(padding-top, $large-leading__laptop);  
	    }

	    @include breakpoint($desktop) {
	        @include rem-size(padding-top, $large-leading__desktop);     
	    }	

	    &__content {
			padding-left: 0;	
			padding-right: 0;
			display: table-cell;		
			height:100%;
			vertical-align: middle;

			@include breakpoint($mobile-horizontal) {
				padding-left: 0;	
				padding-right: 0;
			}
		
			.usp-page__headline {
				margin-bottom: 0;
			}
		}	
	}
}