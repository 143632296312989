/* ADDS A BROWSER PREFIX TO THE PROPERTY */
@mixin css3-prefix($property, $value) {
  -webkit-#{$property}: #{$value};
   -khtml-#{$property}: #{$value};
     -moz-#{$property}: #{$value};
      -ms-#{$property}: #{$value};
       -o-#{$property}: #{$value};
          #{$property}: #{$value};
}

@mixin transform($property: none) {
  // none | <transform-function>
  @include css3-prefix(transform, $property);
}

@mixin transform-origin($axes: 50%) {
  // x-axis - left | center | right  | length | %
  // y-axis - top  | center | bottom | length | %
  // z-axis -                          length
  @include css3-prefix(transform-origin, $axes);
}

@mixin backface-visibility($visibility) {
  @include css3-prefix(backface-visibility, $visibility);
}

@mixin transition($values...) { 
    $transitions: ();
    @each $declaration in $values {
        $prop: nth($declaration, 1);
        $prop-opts: ();
        $length: length($declaration);
        @for $i from 2 through $length {
            $prop-opts: append($prop-opts, nth($declaration, $i));   
        }
        $trans: ($prop, $prop-opts);
        $transitions: append($transitions, $trans, comma);
    }
      
    -webkit-transition: trans-prefix($transitions, webkit);
    -moz-transition: trans-prefix($transitions, moz);
    -o-transition: trans-prefix($transitions, o);
    transition: $values;
}